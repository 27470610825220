<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
    <v-container id="VEICOLI" fluid tag="section">
      <v-row>
        <v-col :cols="isEditVeicolo ? '4' : '12'">
          <base-material-card
            color="white"
            icon="mdi-car-multiple"
            iconcolor="darkred"
            :title="$t('veicoli')"
            class="px-5 py-3"
            backgroundcolor=""
            titlecolor="red"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('veicoli.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="visualizzaStorico"
                    :label="$t('visualizzaStorico')"
                    hide-details
                    @change="checkStorico($event)"
                  >
                  </v-checkbox>
                </v-col>
                <v-col align="end">
                  <v-btn color="darkred" width="150px" @click="onNuovoVeicolo">
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("veicoli.nuovoVeicolo") }}
                  </v-btn>
                  <v-btn 
                    color="darkred" 
                    width="150px"
                    @click="onExportGrid">>
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMain"
              :items="veicoli.source"
              :headers="headersColumns"
              :search="search"
              :value="[veicoloSel]"
              @click:row="onTableRowClick"
              single-select
              :items-per-page="-1"
              class="elevation-1"
              :loading="isloading_veicoli"
              loading-text="Caricamento dati in corso..."
            >
            </v-data-table>
          </base-material-card>
        </v-col>

        <v-col v-if="isEditVeicolo" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-car"
            :title="$t('page.veicolo.titolo') + ': ' + veicoloSel.cSigla"
            class="px-5 py-3"
          >
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab
                v-for="item in veicoliTabs"
                :key="item"
                @change="getTabSelected(item)"
              >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in veicoliTabs" :key="item" eager>
                <v-card v-if="item == 'Anagrafica'" flat>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.cSigla"
                        :label="$t('sigla')"
                        :rules="[rules.required]"
                        maxlength="10"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nInventario"
                        :label="$t('inventario')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.valueMin(veicoloSel.nInventario, 0), rules.valueMax(veicoloSel.nInventario, 999999)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.cMarca"
                        :label="$t('marca')"
                        :rules="[rules.required]"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.cModello"
                        :label="$t('modello')"
                        :rules="[rules.required]"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.cTarga"
                        :label="$t('targa')"
                        :rules="[rules.required]"
                        maxlength="10"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.cTargaPrec"
                        :label="$t('targaPrec')"
                        maxlength="10"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="t_categorieveicolo"
                            return-object
                            item-text="cDescrizione"
                            item-value="id"
                            :label="$t('veicolo.categoria')"
                            v-model="veicoloSel.tipoVeicoloCategoria"
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                        <v-col>
                          <v-select
                            :items="t_destinazioneuso"
                            return-object
                            item-text="cDescrizione"
                            item-value="id"
                            :label="$t('veicolo.destinazioneUso')"
                            v-model="veicoloSel.tipoVeicoloDestUso"
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="t_tipotrasporti"
                            return-object
                            item-text="cDescrizione"
                            item-value="id"
                            :label="$t('veicolo.tipoTrasporto')"
                            v-model="veicoloSel.tipoVeicoloTipTras"
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                        <v-col>
                          <v-select
                            :items="t_tipousi"
                            return-object
                            item-text="cDescrizione"
                            item-value="id"
                            :label="$t('veicolo.tipoUso')"
                            v-model="veicoloSel.tipoVeicoloUso"
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="veicoloSel.dataImmatricText"
                            :label="$t('immatricolatoIl')"
                            type="date"
                            min="1990-01-01"
                            max="2050-12-31"
                            clearable
                            :rules="[rules.required]"
                            :disabled="shouldDisable"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-select
                            :items="eEntiProp"
                            return-object
                            item-text="cDescrizione"
                            item-value="id"
                            :label="$t('proprietario')"
                            v-model="veicoloSel.enteProp"
                            :rules="[rules.selectRequired]"
                            :disabled="enteShouldDisable"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-textarea
                        v-model="veicoloSel.xNote"
                        height="240px"
                        outlined
                        name="txtNote"
                        maxlength="255"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.kmfatti"
                        :label="$t('veicolo.kmFattiObbl')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.required, rules.valueMin(veicoloSel.kmfatti, 0), rules.valueMax(veicoloSel.kmfatti, 999999)]"
                        :disabled="!isNewVeicolo"
                        @keydown="onKeyDownNegative"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.ultimoRifornimento"
                        :label="$t('ultimoRifornimento')"
                        :disabled="true"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="veicoloSel.flagInManut"
                        :label="$t('inManutenzione')"
                        hide-details
                        :disabled="shouldDisable"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Dati tecnici'" flat>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="t_carburante"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('veicolo.carburante1')"
                        :rules="[rules.selectRequired]"
                        v-model="veicoloSel.tipoVeicoloCarbur1"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nHpMax1"
                        :label="$t('veicolo.hpMax')"
                        :rules="[rules.valueMin(veicoloSel.nHpMax1, 0), rules.valueMax(veicoloSel.nHpMax1, 99999)]"
                        min="1"
                        max="99999"
                        type="number"
                        step="0.01"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nKwMax1"
                        :label="$t('veicolo.kwMax')"
                        :rules="[rules.valueMin(veicoloSel.nKwMax1, 0), rules.valueMax(veicoloSel.nKwMax1, 99999)]"
                        type="number"
                        min="1"
                        max="99999"
                        step="0.01"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="t_carburante2"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('veicolo.carburante2')"
                        v-model="veicoloSel.tipoVeicoloCarbur2"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nHpMax2"
                        :label="$t('veicolo.hpMax2')"
                        :rules="[rules.valueMin(veicoloSel.nHpMax2, 0), rules.valueMax(veicoloSel.nHpMax2, 99999)]"
                        type="number"
                        min="1"
                        max="99999"
                        step="0.01"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nKwMax2"
                        :label="$t('veicolo.kwMax2')"
                        :rules="[rules.valueMin(veicoloSel.nKwMax2, 0), rules.valueMax(veicoloSel.nKwMax2, 99999)]"
                        type="number"
                        min="1"
                        max="99999"
                        step="0.01"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="t_emissione"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('veicolo.tipoEmissione')"
                        v-model="veicoloSel.tipoVeicoloTipoEmis"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="t_classeambientale"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('veicolo.classe')"
                        v-model="veicoloSel.tipoVeicoloClasseAmb"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nCvFiscali"
                        :label="$t('veicolo.cvFiscali')"
                        type="number"
                        min="1"
                        max="99"
                        :rules="[rules.valueMin(veicoloSel.nCvFiscali, 0), rules.valueMax(veicoloSel.nCvFiscali, 99)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nCilindrata"
                        :label="$t('veicolo.cilindrata')"
                        type="number"
                        min="1"
                        max="9999"
                        :rules="[rules.valueMin(veicoloSel.nCilindrata, 0), rules.valueMax(veicoloSel.nCilindrata, 9999)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nNumPosti"
                        :label="$t('veicolo.nPosti')"
                        type="number"
                        min="1"
                        max="99"
                        :rules="[rules.valueMin(veicoloSel.nNumPosti, 0), rules.valueMax(veicoloSel.nNumPosti, 99)]"
                        :disabled="shouldDisable"
                        @keydown="onKeyDown"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nPortataKg"
                        :label="$t('veicolo.portataKg')"
                        type="number"
                        min="1"
                        max="9999"
                        :rules="[rules.valueMin(veicoloSel.nPortataKg, 0), rules.valueMax(veicoloSel.nPortataKg, 9999)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.cNumTelaio"
                        :label="$t('veicolo.telaio')"
                        :rules="[rules.required]"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nKmMaxMese"
                        :label="$t('veicolo.kmMaxMensili')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.valueMin(veicoloSel.nKmMaxMese, 0), rules.valueMax(veicoloSel.nKmMaxMese, 999999)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="veicoloSel.nKmMaxAuto"
                        :label="$t('veicolo.kmMaxAutonomia')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.valueMin(veicoloSel.nKmMaxAuto, 0), rules.valueMax(veicoloSel.nKmMaxAuto, 999999)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <CompDocuments
                  v-if="item == 'Documenti'"
                  :eDocumenti="veicoloSel.documenti"
                  :t_documenti="t_documenti"
                  :shouldDisable="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-document="loadDocument"
                  v-on:delete-document="deleteDocument"
                />

                <v-card v-if="item == 'Dotazioni'" flat>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="eEntiDotaz"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('ente')"
                        :rules="[rules.selectRequired]"
                        v-model="veicoloSel.dotazioneAttuale.enteDTO"
                        :disabled="enteShouldDisable"
                        @change="onEnteChanged($event)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="eServizi"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('veicolo.servizioAssegnatarario')"
                        :rules="[rules.selectRequired]"
                        v-model="veicoloSel.dotazioneAttuale.servizioDTO"
                        :disabled="shouldDisable"
                        @change="onServizioChanged($event)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="eCapitoliSpesa"
                        return-object
                        item-text="numCapitoloDescrizione"
                        item-value="id"
                        :label="$t('veicolo.capitoliSpesa')"
                        :rules="[rules.selectRequired]"
                        v-model="veicoloSel.dotazioneAttuale.capitoloSpesaDTO"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        v-if="veicoloSel.tipoVeicoloSharing != null"
                        :items="t_tiposharing"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('veicolo.tipoVeicoloSharing')"
                        v-model="veicoloSel.tipoVeicoloSharing"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col v-if="veicoloSel.tipoVeicoloSharing.id == 3">
                      <v-select
                        :items="t_userservizio"
                        return-object
                        item-text="cNomeCognome"
                        item-value="id"
                        :label="$t('veicolo.utenteServizio')"
                        v-model="veicoloSel.utenteSpecialeDTO"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="
                              veicoloSel.dotazioneAttuale.dInizioServizioText
                            "
                            :label="$t('dataInizio')"
                            :rules="[rules.required]"
                            type="date"
                            min="1990-01-01"
                            max="2050-12-31"
                            :disabled="shouldDisable"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="
                              veicoloSel.dotazioneAttuale.dFineServizioText
                            "
                            :label="$t('dataFine')"
                            type="date"
                            min="1990-01-01"
                            max="2050-12-31"
                            :disabled="shouldDisable"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="eUtentiResp"
                            return-object
                            item-text="cNomeCognome"
                            item-value="id"
                            :label="$t('veicolo.responsabile')"
                            :rules="[rules.selectRequired]"
                            v-model="veicoloSel.dotazioneAttuale.utenteRespDTO"
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                        <v-col>
                          <v-select
                            :items="eUtentiUtiliz"
                            return-object
                            item-text="cNomeCognome"
                            item-value="id"
                            :label="$t('utilizzatore')"
                            v-model="
                              veicoloSel.dotazioneAttuale.utenteUtilizDTO
                            "
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="eUbicazioni"
                            return-object
                            item-text="cDescrizione"
                            item-value="id"
                            :label="$t('veicolo.ubicazione')"
                            :rules="[rules.selectRequired]"
                            v-model="veicoloSel.dotazioneAttuale.ubicazioneDTO"
                            :disabled="shouldDisable"
                          >
                          </v-select>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="veicoloSel.dotazioneAttuale.cUbicazioneTmp"
                            :label="$t('ubicazioneTemp')"
                            :disabled="shouldDisable"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-textarea
                        v-model="veicoloSel.dotazioneAttuale.xNote"
                        outlined
                        height="240px"
                        name="txtNoteDotaz"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-divider class="mx-4"></v-divider>
                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                    >{{ $t("veicolo.dotazioniArchiviate") }}
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-data-table
                        dense
                        hide-default-footer
                        :headers="headersDotazioni"
                        :items="veicoloSel.dotazioniArc"
                        item-key=""
                        class="elevation-1"
                      ></v-data-table>
                    </v-col>
                  </v-row>
                </v-card>

                <CompScadenze
                  v-if="item == 'Scadenze'"
                  :eScadenze="veicoloSel.scadenze"
                  :t_tiposcadenze="t_tiposcadenze"
                  :t_tipointerventi="t_tipointerventi"
                  :t_conteggi="t_conteggi"
                  :t_statoscadenza="t_statoscadenza"
                  :shouldDisable="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-scadenza="loadScadenza"
                  v-on:delete-scadenza="deleteScadenza"
                  v-on:update-datascadenza="updateDataScadenza"
                />

                <v-card v-if="item == 'Annotazioni'" flat>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="veicoloSel.xNoteEstese"
                        outlined
                        height="400"
                        name="txtAnnotazioni"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>

            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-btn
                v-show="!isVeicoloStorico"
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = true"
                @click="onEditClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("modifica") }}
              </v-btn>
              <v-btn
                v-show="!isVeicoloStorico"
                tile
                color="darkred"
                v-if="isHidden"
                @click="onSaveClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("salva") }}
              </v-btn>
              <v-btn
                v-show="!isVeicoloStorico"
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = !isHidden"
                @click="onDuplicaClick"
              >
                <v-icon left> mdi-content-duplicate </v-icon>
                {{ $t("duplica") }}
              </v-btn>
              <v-btn
                v-show="!isVeicoloStorico"
                tile
                color="darkred"
                v-if="isDisattivaEnabled"
                @click="onDisattivaClick"
              >
                <v-icon left> mdi-delete </v-icon>
                {{ $t("disattiva") }}
              </v-btn>
              <v-btn
                v-show="!isVeicoloStorico"
                tile
                color="darkred"
                @click="onNotificaClick"
              >
                <v-icon left> mdi-bell </v-icon>
                {{ $t("veicolo.notifica") }}
              </v-btn>
              <v-btn id="btnChiudi" tile color="darkred" @click="closeDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
              <v-dialog v-model="dialogDisattivaVeicolo" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Disattivazione Veicolo</v-card-title
                  >
                    <v-col>
                      <v-text-field
                        v-model="acquirenteVeic"
                        :label="$t('acquirenteVeicolo')">

                      </v-text-field>
                    </v-col>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDisattivaVeicolo"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmDisattivaVeicolo"
                      >OK</v-btn
                    >
                    
                    <v-spacer></v-spacer>
                  </v-card-actions>
                    <v-col cols="11">
                    <v-alert
                      id="alertDisattiva"
                      shaped
                      :value="isAlertDisattivaVisible"
                      :type="alertType"
                      dismissible
                    >
                      {{ messaggioAlert }}
                    </v-alert>
                    </v-col>
                </v-card>
              </v-dialog>

              <v-dialog v-if="dialogNotificaVeicolo" v-model="dialogNotificaVeicolo" max-width="600px">
                <v-card style="width:570px;" >
                  <v-card-title class="text-h5"
                    >Notifica Veicolo</v-card-title
                  >
                    <v-row style="width:550px; margin-left:10px;">
                      <v-col >
                        <v-text-field
                          v-model="notificaTempl.cDest"
                          maxlength="255"
                          :label="$t('emails.a')">

                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row style="width:550px; margin-left:10px;">
                      <v-col>
                        <v-text-field
                          v-model="notificaTempl.cCc"
                          maxlength="255"
                          :label="$t('emails.cc')">

                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row style="width:550px; margin-left:10px;">
                      <v-col>
                        <v-text-field
                          v-model="notificaTempl.cOggetto"
                          maxlength="100"
                          :label="$t('emails.oggetto')">

                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row style="width:550px; margin-left:10px;">
                      <v-col>
                        <v-textarea
                          v-model="notificaTempl.xTesto"
                          height="250px"
                          outlined
                          maxlength="2000"
                          :label="$t('emails.testo')"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>                      
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeNotificaVeicolo"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmNotificaVeicolo"
                      >OK</v-btn
                    >
                    
                    <v-spacer></v-spacer>
                  </v-card-actions>
                    <v-col cols="11">
                    <v-alert
                      id="alertDisattiva"
                      shaped
                      :value="isAlertDisattivaVisible"
                      :type="alertType"
                      dismissible
                    >
                      {{ messaggioAlert }}
                    </v-alert>
                    </v-col>
                </v-card>
              </v-dialog>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";
import axios from "axios";

import { environment } from "@/environment/environment";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import TipologicheService from "@/service/TipologicheService";
import VeicoliService from "@/service/VeicoliService";
import EntiService from "@/service/EntiService";
import ServiziService from "@/service/ServiziService";
import CapitoliService from "@/service/CapitoliService";
import UtentiService from "@/service/UtentiService";
import ExcelService from "@/service/ExcelService";
import NotificheService from "@/service/NotificheService";

import CompDocuments from "@/components/CompDocuments";
import CompScadenze from "@/components/CompScadenze";

@Component({
  components: {
    CompDocuments,
    CompScadenze,
  },
})
export default class Veicoli extends Vue {
  veicoli = {
    select: [],
    selected: null,
    source: [],
  };
  
  rules = Utils.rules;

  eServizi = [];

  eCapitoliSpesa = [];

  eUtenti = [];

  eUtentiResp = [];

  eUtentiUtiliz = [];

  eEntiProp = [];

  eEntiDotaz = [];

  eUbicazioni = [];

  t_carburante = [];
  t_carburanteOrig = [];

  t_carburante2 = [];
  t_carburante2Orig = [];

  t_documenti = [];
  t_documentiOrig = [];

  t_emissione = [];
  t_emissioneOrig = [];

  t_classeveicolo = [];
  t_classeveicoloOrig = [];

  t_classeambientale = [];
  t_classeambientaleOrig = [];

  t_tiposcadenze = [];
  t_tiposcadenzeOrig = [];

  t_tipointerventi = [];
  t_tipointerventiOrig = [];

  t_conteggi = [];
  t_conteggiOrig = [];

  t_statoscadenza = [];
  t_statoscadenzaOrig = [];

  t_scad_categoria = [];
  t_scad_categoriaOrig = [];

  t_categorieveicolo = [];
  t_categorieveicoloOrig = [];

  t_destinazioneuso = [];
  t_destinazioneusoOrig = [];

  t_tipotrasporti = [];
  t_tipotrasportiOrig = [];

  t_tipousi = [];
  t_tipousiOrig = [];

  t_tiposharing = [];
  t_tiposharingOrig = [];

  t_userservizio = [];
  t_userservizioOrig = [];

  //t_tipousi = [ { id: 1, cDescrizione:"Uso Proprio"}, {id:2, cDescrizione:"Uso Terzi"}];

  veicoliTabs = [
    "Anagrafica",
    "Dati tecnici",
    "Documenti",
    "Dotazioni",
    "Scadenze",
    "Annotazioni",
  ];

  headersDotazioni = [
    {
      text: "Ente",
      align: "start",
      value: "enteDTO.cDescrizione",
    },
    { text: "Servizio Assegnatario", value: "servizioDTO.cDescrizione" },
    { text: "Sigla", value: "cSigla" },
    { text: "Inizio dotazione", value: "dInizioServizioText" },
    { text: "Fine dotazione", value: "dFineServizioText" },
    { text: "Responsabile", value: "utenteRespDTO.cNomeCognome" },
  ];

  headersColumnsRidotte = [
    {
      text: "Sigla",
      align: "left",
      value: "cSigla",
    },
    { text: "Inventario", value: "nInventario" },
    { text: "Marca", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
  ];

  headersColumnsOrig = [
    {
      text: "Sigla",
      align: "left",
      value: "cSigla",
    },
    { text: "Inventario", value: "nInventario" },
    { text: "Modello", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
    { text: "In dotazione a", value: "dotazioneAttuale.servizioDTO.cDescrizione" },
    { text: "Classe veicolo", value: "tipologiaVeicolo" },
    { text: "Tipo Trasporto", value: "tipoVeicoloTipTras.cDescrizione" },
    { text: "Responsabile", value: "dotazioneAttuale.utenteRespDTO.cNomeCognome" },
    { text: "Utilizzatore", value: "dotazioneAttuale.utenteUtilizDTO.cNomeCognome" },
    { text: "Ente", value: "dotazioneAttuale.enteDTO.cDescrizione" },
  ];

  headersColumnsStorico = [
    {
      text: "Sigla",
      align: "left",
      value: "cSigla",
    },
    { text: "Inventario", value: "nInventario" },
    { text: "Modello", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
    { text: "In dotazione a", value: "dotazioneAttuale.servizioDTO.cDescrizione" },
    { text: "Classe veicolo", value: "tipologiaVeicolo" },
    { text: "Tipo Trasporto", value: "tipoVeicoloTipTras.cDescrizione" },
    { text: "Responsabile", value: "dotazioneAttuale.utenteRespDTO.cNomeCognome" },
    { text: "Utilizzatore", value: "dotazioneAttuale.utenteUtilizDTO.cNomeCognome" },
    { text: "Ente", value: "dotazioneAttuale.enteDTO.cDescrizione" },
    { text: "Disattivato", value: "Disattivo" },
  ];

  // Variabili
  veicoloSel = null;
  veicoloTemplate = null;
  documentoTempl = null;
  scadenzaTempl = null;
  notificaTempl = null;
  docSelected = null;
  //scadenzaSel = null;
  docDaElab = null;
  acquirenteVeic = null;
  dialog = false;
  dialogDeleteDoc = false;
  dialogDisattivaVeicolo = false;
  docToDelete = null;
  dialogDeleteScad = false;
  dialogNotificaVeicolo = false;
  scadToDelete = null;
  fileSel = null;
  fileSel2 = [];
  fileName = "";
  tab = null;
  isNewVeicolo = false;
  isEditVeicolo = false;
  isHidden = false;
  isVeicoloStorico = false;
  shouldDisable = true;
  enteShouldDisable = true;
  //shouldDisableScad = true;
  dimension = 0;
  titolo = "";
  isloading_veicoli = false;
  isAlertDetailVisible = false;
  isAlertGridVisible = false;
  isAlertDisattivaVisible = false;
  dataImmat = null;
  menu2 = false;
  messaggioAlert = "";
  alertType = "error";
  search = "";
  visualizzaStorico = false;
  saveClicked = false; 
  isCreatedCorrectly = false;
  isDisattivaEnabled = false;

  mounted() {
    if (this.isCreatedCorrectly)
      this.onContainerChange();
  }

  created() {
    // Verifica presenza Utente loggato / Ente selezionato / Permessi
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_VEICOLI");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        var enteId = this.$store.state.enteSel.id;
        if (this.$store.state.roleSel.CCode == "ADM") 
          this.isDisattivaEnabled = true;
        else
          this.isDisattivaEnabled = false;

        // Caricamento oggetto principale
        this.loadVeicoli(enteId, this.isVeicoloStorico);
        this.loadEnti();
          
        this.loadAllTipologiche();
/*
        this.loadTDocumenti();
        // Scadenze
        this.loadTScadenzaTipo();
        this.loadTInterventoTipo();
        this.loadTScadenzaConteggio();
        this.loadTScadenzaStato();
*/

        // Mi faccio restituire i template
        this.getTemplateVeicolo();
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }

  }

  checkStorico(e) {
    var enteId = this.$store.state.enteSel.id;
    // Caricamento oggetto principale
    this.loadVeicoli(enteId, this.visualizzaStorico);
    this.closeDetail();
  }

  onTableRowClick(veicolo, row) {
    // Setto la riga selezionata
    this.veicoloSel = veicolo;
    this.loadVeicolo(this.veicoloSel.id, this.onContainerChange);
    this.titolo = "$t('page.veicolo.titolo')" + " " + this.veicoloSel.cSigla;
    console.log("Veicolo selezionato: ");
    console.log(this.veicoloSel);
    this.loadTemplateNotificheVeicolo(this.veicoloSel.id);    

    if (!this.isEditVeicolo)
      this.tab = "Anagrafica";
    // Carico le tipologiche dell'elemento selezionato
    this.fillTipologicheERelazioni();
    this.onContainerChange();
    
    this.shouldDisable = true;
    this.isHidden = false;
    this.isNewVeicolo = false;
    this.isEditVeicolo = true;
    
    this.manageEntiVisibility(false);

    if (this.veicoloSel.hDel != null) this.isVeicoloStorico = true;
    else this.isVeicoloStorico = false;
  }
  
  getTabSelected(tabId) {
    if (this.saveClicked) this.$refs.form.validate();
  }

  closeDetail() {
    this.isEditVeicolo = false;
    this.onContainerChange();
    this.titolo = "$t('page.veicolo.titolo')";
    this.shouldDisable = true;
    this.isHidden = false;
  }

  get headersColumns() {
    let tblWidth = this.dimension;
    //console.log(tblWidth);
    if (tblWidth < 1000) return this.headersColumnsRidotte;
    else 
    {
      if (!this.visualizzaStorico)
        return this.headersColumnsOrig;
      else
        return this.headersColumnsStorico
    }
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMain").offsetWidth;
      return self.dimension;
    });
  }

  onEnteChanged(item) {
    if (this.veicoloSel.dotazioneAttuale.enteDTO.id != -1)
      this.loadEnteAndLinkObjects(this.veicoloSel.dotazioneAttuale.enteDTO.id);
  }

  onServizioChanged(item) {
    if (this.veicoloSel.dotazioneAttuale.servizioDTO.id != -1)
    {
      // Carico la tipologica degli utenti speciali
      var serv = -1;
      if (this.veicoloSel.dotazioneAttuale.servizioDTO != null)
        serv = this.veicoloSel.dotazioneAttuale.servizioDTO.id;
      this.loadUtentiSpeciali(serv);      
    }
  }

  loadAllTipologiche() {
    // Caricamento di tutte le Tipologiche
    // Veicoli
    this.loadTVeicoloCarbur();
    this.loadTVeicoloCategoria();
    this.loadTVeicoloClasseAmb();
    this.loadTVeicoloDestUso();
    this.loadTVeicoloTipoEmis();
    this.loadTVeicoloTipTras();
    this.loadTVeicoloTipSharing();
    this.loadTVeicoloUso();
    // Documenti
    this.loadTDocumenti();
    // Scadenze
    this.loadTScadenzaTipo();
    this.loadTInterventoTipo();
    this.loadTScadenzaConteggio();
    this.loadTScadenzaStato();
  }

loadAllTipologicheAttive(soloAttive) {
    // Carico un dato tipico del servizio selezionato
    var serv = -1;
    if (this.veicoloSel.dotazioneAttuale != null && this.veicoloSel.dotazioneAttuale.servizioDTO != null)
      serv = this.veicoloSel.dotazioneAttuale.servizioDTO.id;
    this.loadUtentiSpeciali(serv);

    // Caricamento di tutte le Tipologiche
    if (soloAttive)
    {
      // SOLO le tipologiche attive
      this.t_carburante = Utils.getTipologicheAttive(this.t_carburanteOrig);
      this.t_carburante2 = Utils.getTipologicheAttive(this.t_carburante2Orig);
      this.t_documenti = Utils.getTipologicheAttive(this.t_documentiOrig);
      this.t_emissione = Utils.getTipologicheAttive(this.t_emissioneOrig);
      this.t_classeveicolo = Utils.getTipologicheAttive(this.t_classeveicoloOrig);
      this.t_classeambientale = Utils.getTipologicheAttive(this.t_classeambientaleOrig);
      this.t_tiposcadenze = Utils.getTipologicheAttive(this.t_tiposcadenzeOrig);
      this.t_tipointerventi = Utils.getTipologicheAttive(this.t_tipointerventiOrig);
      this.t_conteggi = Utils.getTipologicheAttive(this.t_conteggiOrig);
      this.t_statoscadenza = Utils.getTipologicheAttive(this.t_statoscadenzaOrig);
      this.t_scad_categoria = Utils.getTipologicheAttive(this.t_scad_categoriaOrig);
      this.t_categorieveicolo = Utils.getTipologicheAttive(this.t_categorieveicoloOrig);
      this.t_destinazioneuso = Utils.getTipologicheAttive(this.t_destinazioneusoOrig);
      this.t_tipotrasporti = Utils.getTipologicheAttive(this.t_tipotrasportiOrig);
      this.t_tipousi = Utils.getTipologicheAttive(this.t_tipousiOrig);
      this.t_tiposharing = Utils.getTipologicheAttive(this.t_tiposharingOrig);
      this.t_userservizio = Utils.getTipologicheAttive(this.t_userservizioOrig);
    }
    else
    {
      // TUTTE le tipologiche
      this.t_carburante = this.t_carburanteOrig;
      this.t_carburante2 = this.t_carburante2Orig;
      this.t_documenti = this.t_documentiOrig;
      this.t_emissione = this.t_emissioneOrig;
      this.t_classeveicolo = this.t_classeveicoloOrig;
      this.t_classeambientale = this.t_classeambientaleOrig;
      this.t_tiposcadenze = this.t_tiposcadenzeOrig;
      this.t_tipointerventi = this.t_tipointerventiOrig;
      this.t_conteggi = this.t_conteggiOrig;
      this.t_statoscadenza = this.t_statoscadenzaOrig;
      this.t_scad_categoria = this.t_scad_categoriaOrig;
      this.t_categorieveicolo = this.t_categorieveicoloOrig;
      this.t_destinazioneuso = this.t_destinazioneusoOrig;
      this.t_tipotrasporti = this.t_tipotrasportiOrig;
      this.t_tipousi = this.t_tipousiOrig;
      this.t_tiposharing = this.t_tiposharingOrig;
      this.t_userservizio = this.t_userservizioOrig;
    }
  }

  onEditClick() {
    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);
    // Caricamento oggetti principali in relazione
    this.loadEnti(true);

    // Carico da db l'oggetto Veicolo selezionato
    this.loadVeicolo(this.veicoloSel.id, this.onContainerChange);
    this.manageEntiVisibility(true);
    this.shouldDisable = false;
  }

  onNuovoVeicolo() {
    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.saveClicked = false;
    this.isNewVeicolo = true;

    this.veicoloSel = Object.assign({}, this.veicoloTemplate);
    this.veicoloSel.documenti = [];
    //this.loadTemplateNotificheVeicolo(this.veicoloSel.id);    
    this.veicoloSel.dotazioneAttuale = Object.assign(
      {},
      this.veicoloTemplate.dotazioneAttuale
    );
    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);
    // Caricamento oggetti principali in relazione
    this.loadEnti(true);
    // Impostazione variabili
    this.isVeicoloStorico = false;
    this.isEditVeicolo = true;
    this.shouldDisable = false;
    this.isHidden = true;
    // Svuoto oggetti collegati
    //this.eScadenze = [];
    //this.eDocumenti = [];
    this.eServizi = [];
    this.eCapitoliSpesa = [];
    this.eUtentiResp = [];
    this.eUtentiUtiliz = [];
    this.eUbicazioni = [];

    this.onEnteChanged();
    this.onContainerChange();

    this.tab = "Anagrafica";
    this.manageEntiVisibility(true);

    this.$refs.form.resetValidation();
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      if (this.veicoloSel.tipoVeicoloSharing.id == 3 && (
        this.veicoloSel.utenteSpecialeDTO == null || this.veicoloSel.utenteSpecialeDTO.id <= 0))
        {
          this.alertType = "error";
          // In caso di errore del salvataggio continuare con l'editing
          this.messaggioAlert = i18n.t("veicolo.erroreUtenteSpeciale");
          this.showHide_alertDetail(8000);
          return;
        }
      if (this.veicoloSel.id > 0)
        // Salvo il dato sul DB
        this.saveVeicoloDto(this.veicoloSel.id);
      else 
        // Creo il nuovo record
        this.createVeicoloDto();
    }
    this.manageEntiVisibility(true);
  }

  onDuplicaClick() {
    var enteId = this.$store.state.enteSel.id;
    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);

    // Caricamento oggetti principali in relazione
    this.loadEnteAndLinkObjects(enteId);

    var veicoloDupl = Object.assign({}, this.veicoloSel);
    //veicoloDupl = [];
    //Object.assign(veicoloDupl, this.veicoloSel);

    // Azzero parametri da azzerare
    veicoloDupl.cSigla = "";
    veicoloDupl.nInventario = "";
    veicoloDupl.cNumTelaio = "";
    veicoloDupl.cTarga = "";
    veicoloDupl.cTargaPrec = "";
    veicoloDupl.id = -1;
    veicoloDupl.kmfatti = 0;
    veicoloDupl.ultimoRifornimento = "";
    veicoloDupl.dotazioniArc = [];
    veicoloDupl.dotazioneAttuale = Object.assign(
      {},
      this.veicoloTemplate.dotazioneAttuale
    );

    veicoloDupl.scadenze = [];
    veicoloDupl.documenti = [];
    // Riassegno il dato bindato
    this.veicoloSel = veicoloDupl;
    // Metto in editing il veicolo
    this.manageEntiVisibility(true);
    this.isNewVeicolo = true;
    this.shouldDisable = false;
  }

  onDisattivaClick() {
    this.dialogDisattivaVeicolo = true;
  }

  onNotificaClick() {
    if (this.veicoloSel.id > 0)
    {
      this.loadTemplateNotificheVeicolo(this.veicoloSel.id);
      this.dialogNotificaVeicolo = true;
    }
    else
    {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("veicolo.erroreVisualizzazioneNotifica");
      this.showHide_alertDetail(8000);
      return
    }
  }

  onKeyDown(key) {
    if (key.key == "." || key.key == ",")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  onKeyDownNegative(key) {
    if (key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  confirmDisattivaVeicolo() {
    if (!Utils.isNullOrUndefined(this.acquirenteVeic))
    {
      this.disattivaVeicoloDto(this.veicoloSel.id, this.acquirenteVeic);
      this.dialogDisattivaVeicolo = false;
    }
    else
    {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreAcquirenteVeicolo");
        this.showHide_alertDisattiva(8000);
    }
  }

  closeDisattivaVeicolo() {
    this.dialogDisattivaVeicolo = false;
  }

  confirmNotificaVeicolo() {
    if (Utils.isNullOrUndefinedOrEmpty(this.notificaTempl.cDest) ||
      Utils.isNullOrUndefinedOrEmpty(this.notificaTempl.cOggetto) ||
      Utils.isNullOrUndefinedOrEmpty(this.notificaTempl.xTesto)
    ) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("veicolo.erroreCampiObbligatoriNotifica");
      this.showHide_alertDisattiva(8000);
      return
    }

    // Creo la notifica
    this.createNotificaVeicoloDto(this.notificaTempl);
    this.dialogNotificaVeicolo = false;

  }

  closeNotificaVeicolo() {
    this.dialogNotificaVeicolo = false;
  }

  loadScadenza(scadenzaTemp) {
    if (scadenzaTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      scadenzaTemp.guid = Utils.createGuid();
      if (this.veicoloSel.id > 0) scadenzaTemp.eVeicoliId = this.veicoloSel.id;
      this.veicoloSel.scadenze.push(scadenzaTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.veicoloSel.scadenze.length; i++) {
        var item = this.veicoloSel.scadenze[i];
        if (item.guid == scadenzaTemp.guid) {
          item = scadenzaTemp;
        }
      }
    }
  }

  deleteScadenza(guid) {
    for (let i = 0; i < this.veicoloSel.scadenze.length; i++) {
      var item = this.veicoloSel.scadenze[i];
      if (item.guid == guid) {
        if (item.id == 0)
          this.veicoloSel.scadenze.splice(i, 1);
        else
          item.hDel = new Date();
        break;
      }
    }
  }

  updateDataScadenza(scadenza) {
    // Verificare perchè Non aggiorna il dato nella griglia principale dei veicoli
    // TODO
    for (let i = 0; i < this.veicoloSel.scadenze.length; i++) {
      var item = this.veicoloSel.scadenze[i];
      if (item.guid == scadenza.guid) {
        item = scadenza;
        break;
      }
    }
  }

  loadDocument(docTemp) {
    if (docTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      docTemp.guid = Utils.createGuid();
      if (this.veicoloSel.id > 0) 
        docTemp.objectId = this.veicoloSel.id;
      this.veicoloSel.documenti.push(docTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.veicoloSel.documenti.length; i++) {
        var item = this.veicoloSel.documenti[i];
        if (item.guid == docTemp.guid) {
          item = docTemp;
        }
      }
    }
  }

  deleteDocument(guid) {
    for (let i = 0; i < this.veicoloSel.documenti.length; i++) {
      var item = this.veicoloSel.documenti[i];
      if (item.guid == guid) {
        item.hDel = new Date();
        break;
      }
    }
  }

  showAlertMessage(alertType, localVariableName) {
    this.alertType = alertType;
    this.messaggioAlert = i18n.t(localVariableName);
    if (alertType == "error")
      this.showHide_alertDetail(8000);
    else
      this.showHide_alertDetail(5000);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  showHide_alertDisattiva(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDisattivaVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDisattivaVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  fillTipologicheERelazioni() {
    // Tipologiche
    this.t_carburante = [this.veicoloSel.tipoVeicoloCarbur1];
    this.t_carburante2 = [this.veicoloSel.tipoVeicoloCarbur2];
    this.t_categorieveicolo = [this.veicoloSel.tipoVeicoloCategoria];
    this.t_classeambientale = [this.veicoloSel.tipoVeicoloClasseAmb];
    this.t_destinazioneuso = [this.veicoloSel.tipoVeicoloDestUso];
    this.t_emissione = [this.veicoloSel.tipoVeicoloTipoEmis];
    this.t_tipotrasporti = [this.veicoloSel.tipoVeicoloTipTras];
    this.t_tiposharing = [this.veicoloSel.tipoVeicoloSharing];
    this.t_userservizio = [this.veicoloSel.utenteSpecialeDTO]
    this.t_tipousi = [this.veicoloSel.tipoVeicoloUso];
    // Relazioni
    this.eEntiProp = [this.veicoloSel.enteProp];
    this.eServizi = [this.veicoloSel.dotazioneAttuale.servizioDTO];
    this.eCapitoliSpesa = [this.veicoloSel.dotazioneAttuale.capitoloSpesaDTO];
    this.eUtentiResp = [this.veicoloSel.dotazioneAttuale.utenteRespDTO];
    this.eUtentiUtiliz = [this.veicoloSel.dotazioneAttuale.utenteUtilizDTO];
    this.eEntiDotaz = [this.veicoloSel.dotazioneAttuale.enteDTO];
    this.eUbicazioni = [this.veicoloSel.dotazioneAttuale.ubicazioneDTO];
    //this.eDocumenti = this.veicoloSel.documenti;
    //this.eScadenze = this.veicoloSel.scadenze;

    for (let i = 0; i < this.veicoloSel.documenti.length; i++) {
      var item = this.veicoloSel.documenti[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }
    for (let i = 0; i < this.veicoloSel.scadenze.length; i++) {
      var item = this.veicoloSel.scadenze[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }

    // Scadenze
    //this.t_tiposcadenze = [this.scadenzaSel.scadenzaTipo];
    //this.t_tipointerventi = [this.scadenzaSel.interventoTipo];
    //this.t_conteggi = [this.scadenzaSel.scadenzaConteggio];
    //this.t_statoscadenza = [this.scadenzaSel.scadenzaStato];

    //
    //this.loadDocumenti(this.veicoloSel.id, false);
  }

  manageEntiVisibility(isEditing) {
    // Gestione Utente / Profilazione
        if (this.$store.state.roleSel != null)
        {
          if (this.$store.state.roleSel.CCode == "ADM")
          {
            if (this.$store.state.enteSel.id == 0)
            {
              // Può selezionare
              // Abilito le select degli enti
              if (isEditing)
                this.enteShouldDisable = false;
              else
              this.enteShouldDisable = true;
            }
            else
            {
              // E' bloccato come un utente qualsiasi
              // Setto l'ente selezionato in Dashboard
              if (!Utils.isNullOrUndefined(this.veicoloSel))
              {
                this.veicoloSel.enteProp = this.$store.state.enteSel;
                if (Utils.isNullOrUndefinedOrEmpty(this.veicoloSel.dotazioneAttuale.hDel))
                {
                  this.veicoloSel.dotazioneAttuale.enteDTO = this.$store.state.enteSel;
                }
                // Disabilito le select degli enti
                this.enteShouldDisable = true;
              }
            }
          }
          else
          {
            if (!Utils.isNullOrUndefined(this.veicoloSel))
            {
              this.veicoloSel.enteProp = this.$store.state.enteSel;
              this.veicoloSel.dotazioneAttuale.enteDTO = this.$store.state.enteSel;
            }
            // Disabilito le select degli enti
            this.enteShouldDisable = true;
          }
        }
  }

  // ************************
  // DATABASE
  // ************************
  loadVeicoli(enteId, storico) {
    var self = this;
    self.isloading_veicoli = true;

    VeicoliService.getVeicoli(
      enteId,
      storico,
      (resp) => {
        self.isloading_veicoli = false;
        console.log("Veicoli:");
        console.log(resp);

        self.veicoli.source = resp;
        for (let i = 0; i < self.veicoli.source.length; i++) {
          var item = self.veicoli.source[i];
          if (item.hDel != null) 
            item.Disattivo = "Si";
          else
          {
            if (!Utils.isNullOrUndefined(item.dotazioneAttuale))
              item.Disattivo = "No";
            else
              item.Disattivo = "Si";
          }
        }
      },
      (err) => {
        console.log("Error Veicoli:");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoVeicoli");
        self.showHide_alertGrid(8000);
        self.isloading_veicoli = false;
      }
    );
  }

  loadVeicolo(id, onSuccess) {
    var self = this;

    VeicoliService.getVeicolo(
      id,
      (resp) => {
        console.log("Veicolo:");
        console.log(resp);
        self.veicoloSel = resp;
        if (Utils.isNullOrUndefinedOrEmpty(self.veicoloSel.kmfatti)) {
          self.veicoloSel.kmfatti = 1;
        }

        for (let i = 0; i < self.veicoloSel.documenti.length; i++) {
          var item = self.veicoloSel.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        for (let i = 0; i < self.veicoloSel.scadenze.length; i++) {
          var item = self.veicoloSel.scadenze[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        if (typeof onSuccess === "function") onSuccess(resp);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        //self.isloading_fornitori = false;
      }
    );
  }

  loadEnteAndLinkObjects(idEnte) {
    var self = this;
    EntiService.getEnteAndLinkObjects(
      idEnte,
      (resp) => {
        console.log("GetEnteAndLinkObjects");
        console.log(resp);
        self.eServizi = resp.serviziEnte;
        self.eCapitoliSpesa = resp.capitoliSpesaEnte;
        self.eUtentiResp = resp.utentiRespEnte;
        self.eUtentiUtiliz = resp.utentiUtilizEnte;
        self.eUbicazioni = resp.ubicazioniEnte;
        //self.eEntiDotaz = [resp];
        //self.eEntiProp = [resp];
      },
      (err) => {
        console.log("Errore GetEnteAndLinkObjects");
        console.log(err);
      }
    );
  }

  loadEnti(isEditing) {
    var self = this;
    if (isEditing)
      self.enteShouldDisable = true;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEntiProp = resp;
        self.eEntiDotaz = resp;
        if (!Utils.isNullOrUndefined(self.veicoloSel))
          self.loadEnteAndLinkObjects(self.veicoloSel.dotazioneAttuale.enteDTO.id);
      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }

  loadServizi() {
    var self = this;

    ServiziService.getServizi(
      (resp) => {
        console.log("GetServizi");
        console.log(resp);

        self.eServizi = resp;
      },
      (err) => {
        console.log("Errore GetServizi");
        console.log(err);
      }
    );
  }

  loadCapitoli(enteId, storico) {
    var self = this;

    CapitoliService.getCapitoliSpesa(
      enteId,
      storico,
      (resp) => {
        console.log("GetCapitoliSpesa");
        console.log(resp);

        self.eCapitoliSpesa = resp;
      },
      (err) => {
        console.log("Errore GetCapitoliSpesa");
        console.log(err);
      }
    );
  }

  loadUtenti(storico) {
    var self = this;

    UtentiService.getUsers(
      storico,
      (resp) => {
        console.log("GetUsers");
        console.log(resp);

        self.eUtenti = resp;
      },
      (err) => {
        console.log("Errore GetUsers");
        console.log(err);
      }
    );
  }

  // ************************
  // TIPOLOGICHE VEICOLI
  // ************************
  loadTVeicoloUso() {
    var self = this;

    TipologicheService.getTVeicoloUso(
      (resp) => {
        console.log("GetTVeicoloUso");
        console.log(resp);

        self.t_tipousi = resp;
        self.t_tipousiOrig = Utils.cloneArray(self.t_tipousi);
      },
      (err) => {
        console.log("Errore GetTVeicoloUso");
        console.log(err);
      }
    );
  }

  loadTVeicoloTipTras() {
    var self = this;

    TipologicheService.getTVeicoloTipTras(
      (resp) => {
        console.log("GetTVeicoloTipTras");
        console.log(resp);

        self.t_tipotrasporti = resp;
        self.t_tipotrasportiOrig = Utils.cloneArray(self.t_tipotrasporti);
      },
      (err) => {
        console.log("Errore GetTVeicoloTipTras");
        console.log(err);
      }
    );
  }

  loadTVeicoloTipoEmis() {
    var self = this;

    TipologicheService.getTVeicoloTipoEmis(
      (resp) => {
        console.log("GetTVeicoloTipoEmis");
        console.log(resp);

        self.t_emissione = resp;
        self.t_emissioneOrig = Utils.cloneArray(self.t_emissione);
      },
      (err) => {
        console.log("Errore GetTVeicoloTipoEmis");
        console.log(err);
      }
    );
  }

  loadTVeicoloDestUso() {
    var self = this;

    TipologicheService.getTVeicoloDestUso(
      (resp) => {
        console.log("GetTVeicoloDestUso");
        console.log(resp);

        self.t_destinazioneuso = resp;
        self.t_destinazioneusoOrig = Utils.cloneArray(self.t_destinazioneuso);
      },
      (err) => {
        console.log("Errore GetTVeicoloDestUso");
        console.log(err);
      }
    );
  }

  loadTVeicoloClasseAmb() {
    var self = this;

    TipologicheService.getTVeicoloClasseAmb(
      (resp) => {
        console.log("GetTVeicoloClasseAmb");
        console.log(resp);

        self.t_classeambientale = resp;
        self.t_classeambientaleOrig = Utils.cloneArray(self.t_classeambientale);
      },
      (err) => {
        console.log("Errore GetTVeicoloClasseAmb");
        console.log(err);
      }
    );
  }

  loadTVeicoloCategoria() {
    var self = this;

    TipologicheService.getTVeicoloCategoria(
      (resp) => {
        console.log("GetTVeicoloCategoria");
        console.log(resp);

        self.t_categorieveicolo = resp;
        self.t_categorieveicoloOrig = Utils.cloneArray(self.t_categorieveicolo);
      },
      (err) => {
        console.log("Errore GetTVeicoloCategoria");
        console.log(err);
      }
    );
  }

  loadTDocumenti() {
    var self = this;

    TipologicheService.getTDocumentoVeicoloAll(
      (resp) => {
        console.log("GetTDocumentoVeicoloAll");
        console.log(resp);

        self.t_documenti = resp;
        self.t_documentiOrig = Utils.cloneArray(self.t_documenti);
      },
      (err) => {
        console.log("Errore GetTDocumentoVeicoloAll");
        console.log(err);
      }
    );
  }

  loadTVeicoloCarbur() {
    var self = this;

    TipologicheService.getTVeicoloCarbur(
      (resp) => {
        console.log("GetTVeicoloCarbur");
        console.log(resp);

        self.t_carburante = resp;
        self.t_carburanteOrig = Utils.cloneArray(self.t_carburante);
        self.t_carburante2 = Utils.cloneArray(self.t_carburante);
        self.t_carburante2Orig = Utils.cloneArray(self.t_carburante2);
      },
      (err) => {
        console.log("Errore GetTVeicoloCarbur");
        console.log(err);
      }
    );
  }

  loadTVeicoloTipSharing() {
    var self = this;

    TipologicheService.getTVeicoloTipSharing(
      (resp) => {
        console.log("GetTVeicoloTipSharing");
        console.log(resp);

        self.t_tiposharing = resp;
        self.t_tiposharingOrig = Utils.cloneArray(self.t_tiposharing);
      },
      (err) => {
        console.log("Errore GetTVeicoloTipSharing");
        console.log(err);
      }
    );
  }

  loadUtentiSpeciali(idServ) {
    var self = this;

    UtentiService.getUsersServizio(
      idServ,
      (resp) => {
        console.log("GetUsersServizio");
        console.log(resp);

        self.t_userservizio = resp;
        self.t_userservizioOrig = Utils.cloneArray(self.t_userservizio);
      },
      (err) => {
        console.log("Errore GetUsersServizio");
        console.log(err);
      }
    );
  }

  // ************************
  // TIPOLOGICHE SCADENZE
  // ************************
  loadTScadenzaTipo() {
    var self = this;

    TipologicheService.getTScadenzaTipo(
      (resp) => {
        console.log("GetTScadenzaTipo");
        console.log(resp);

        self.t_tiposcadenze = resp;
        self.t_tiposcadenzeOrig = Utils.cloneArray(self.t_tiposcadenze);
      },
      (err) => {
        console.log("Errore GetTScadenzaTipo");
        console.log(err);
      }
    );
  }

  loadTScadenzaStato() {
    var self = this;

    TipologicheService.getTScadenzaStato(
      (resp) => {
        console.log("GetTScadenzaStato");
        console.log(resp);

        self.t_statoscadenza = resp;
        self.t_statoscadenzaOrig = Utils.cloneArray(self.t_statoscadenza);
      },
      (err) => {
        console.log("Errore GetTScadenzaStato");
        console.log(err);
      }
    );
  }

  loadTScadenzaConteggio() {
    var self = this;

    TipologicheService.getTScadenzaConteggio(
      (resp) => {
        console.log("GetTScadenzaConteggio");
        console.log(resp);

        self.t_conteggi = resp;
        self.t_conteggiOrig = Utils.cloneArray(self.t_conteggi);
      },
      (err) => {
        console.log("Errore GetTScadenzaConteggio");
        console.log(err);
      }
    );
  }

  loadTScadenzaCategoria() {
    var self = this;

    TipologicheService.getTScadenzaCategoria(
      (resp) => {
        console.log("GetTScadenzaCategoria");
        console.log(resp);

        self.t_scad_categoria = resp;
        self.t_scad_categoriaOrig = Utils.cloneArray(self.t_scad_categoria);
      },
      (err) => {
        console.log("Errore GetTScadenzaCategoria");
        console.log(err);
      }
    );
  }

  loadTInterventoTipo() {
    var self = this;

    TipologicheService.getTInterventoTipo(
      (resp) => {
        console.log("GetTInterventoTipo");
        console.log(resp);

        self.t_tipointerventi = resp;
        self.t_tipointerventiOrig = Utils.cloneArray(self.t_tipointerventi);
      },
      (err) => {
        console.log("Errore GetTInterventoTipo");
        console.log(err);
      }
    );
  }

  // ************************
  // TEMPLATE
  // ************************
  getTemplateVeicolo() {
    var self = this;

    VeicoliService.getTemplateVeicolo(
      (resp) => {
        console.log("GetTemplateVeicolo:");
        console.log(resp);
        self.veicoloTemplate = resp;
        // Inizializzazione variabili non settate
        self.veicoloTemplate.id = 0;
        self.veicoloTemplate.cSigla = "";
        self.veicoloTemplate.dotazioniArc = [];
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

  onExportGrid() {
    var enteId = this.$store.state.enteSel.id;
    ExcelService.generaExcelVeicoli(
      enteId,
      this.visualizzaStorico,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }

  createVeicoloDto() {
    var self = this;

    self.veicoloSel.dotazioneAttuale.cSigla = self.veicoloSel.cSigla;

    VeicoliService.createVeicolo(
      self.veicoloSel,
      (resp) => {
        console.log("CreateVeicolo");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine

          // Ricarico la griglia
          this.checkStorico(false);
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          self.isNewVeicolo = false;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }
  
  saveVeicoloDto(id) {
    var self = this;

    VeicoliService.updateVeicolo(
      self.veicoloSel,
      (resp) => {
        console.log("UpdateVeicolo");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          for (let i = 0; i < self.veicoli.source.length; i++) {
            var item = self.veicoli.source[i];
            if (item.id == id) {
              self.veicoli.source.splice(i, 1);
              self.veicoli.source.push(self.veicoloSel);
            }
          }
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }
  
  disattivaVeicoloDto(id, acquirente) {
  var self = this;

    VeicoliService.deleteVeicolo(
      id, acquirente,
      (resp) => {
        console.log("DeleteVeicolo");
        console.log(resp.data);
        if (resp.result == 1) {
          // Buon fine
          this.alertType = "success";
          this.messaggioAlert = resp.userMsg.message;
          this.showHide_alertGrid(5000);
          // Azzero l'oggetto
          this.veicoloSel = null;
          // Ricarico la griglia
          this.checkStorico(false);
        } else {
          // KO
          this.alertType = "error";
          this.messaggioAlert = resp.userMsg.message;
          this.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  loadTemplateNotificheVeicolo(idVeicolo) {
    var self = this;
    var userId = this.$store.state.user.id;

    NotificheService.getTemplateNotificaVeicolo(idVeicolo, userId,
      (resp) => {
        console.log("getNotificheUtente");
        console.log(resp);
        
        self.notificaTempl = resp;
      },
      (err) => {
        console.log("Errore getNotificheUtente");
        console.log(err);
        return null;
      }
    );
  }

  createNotificaVeicoloDto(notificaDaInv) {
    var self = this;

    NotificheService.createNotificaVeicolo(
      notificaDaInv,
      (resp) => {
        console.log("createNotificaVeicolo");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

}
</script>

<style>
</style>